
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment } from 'semantic-ui-react'
import LoadCombinationNode from "./LoadCombinationNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import { IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
import RequestStructuralCalculationButton from '../ExportStructuralCalculation/RequestStructualCalculation';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class LoadGroupTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            LoadCombinations : [],
            Active: {},
            ActiveSupport: {},
            ActiveTipping: {},
            Settings: {},

            sorting: null,
            indexMap: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.updateLoadCombination();
    }

    onDragEnd = (e) => {
        window.LR_ChangeLoadCombinationOrder({UUID: this.state.drag.source, Index: this.state.drag.target})
    }

    render() 
    {

        return (
            <div style={{width:"100%", height:"100%"}}>
            <LoadCombinationNode settings entry={this.state.Settings}></LoadCombinationNode>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5' style={{zIndex: 0}} onClick={this.onHeaderClick}>
                                {<Icon link style={{floated: 'left', position: 'absolute',zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        value   = {this.state.value}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.LoadCombinations)}
                                        size = {window.IsIOS ? "mini" : "large"}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                            {this.renderRows()}
                        </Table.Body>
                    </Table>
                    <RequestStructuralCalculationButton/>

                   {window.IsIOS ? 
                   <Segment vertical textAlign="center" style={{width:"100%", border:"none"}}>
                    <Label as="a" color="green" onClick={ this.addNewLoadCombination }>
                        <Icon name="plus"/>{LocalizedStrings.AddNewLoadCombination}
                    </Label>
                    </Segment> : null}

                </div>

                {!window.IsIOS ? <Segment vertical textAlign="center" style={{position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                    <Label as="a" color="green" onClick={ this.addNewLoadCombination }>
                        <Icon name="plus"/>{LocalizedStrings.AddNewLoadCombination}
                    </Label>
                </Segment> : null}
             </div>

        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {
        let showData = !this.state.value ? this.state.LoadCombinations : this.state.isLoading ? this.state.LoadCombinations : this.state.results

        if(this.state.sorting) {
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return showData[this.state.indexMap[calcIndex]]
            }).map((entry) => <LoadCombinationNode key={entry.UUID} entry={entry} active={entry.UUID === this.state.Active.UUID} 
            activeSupport={entry.UUID === this.state.ActiveSupport.UUID} 
            activeTipping={entry.UUID === this.state.ActiveTipping.UUID} 
            />)
        }

        return showData.map((entry, idx) => {
            return <LoadCombinationNode 
            draggable={this.state.sorting === null}
            onDragStart={()=>this.setState({drag: {source: entry.UUID,              target: idx}})}
            onDragEnter={()=>this.setState({drag: {source: this.state.drag.source,  target: idx}})}
            onDragEnd={this.onDragEnd}
            key={entry.UUID} 
            entry={entry} 
            activeSupport={entry.UUID === this.state.ActiveSupport.UUID} 
            activeTipping={entry.UUID === this.state.ActiveTipping.UUID} 
            active={entry.UUID === this.state.Active.UUID}/>
        })
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }


    addNewLoadCombination = () =>
    {
        window.LR_AddNewLoadCombination({})
    }

    setUpCallbacks() 
    {
        globalCallbacks.updateLoadCombination = async () =>
        {
            let res = await window.LR_GetLoadCombinations()

            let indexMap = []
            let objects = res.LoadCombinations
            objects.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))
            this.setState({LoadCombinations: res.LoadCombinations, indexMap})

            globalCallbacks.updateActiveLoadCombination()
        }

        globalCallbacks.updateActiveLoadCombination = async () =>
        {

            let active =    await window.LR_GetActiveLoadCombination()
            this.setState({Active: active,ActiveTipping: active.TippingLoadCombination, ActiveSupport: active.SupportLoadCombination, Settings: active.Settings})
        }

    }
}

export default LoadGroupTable;

