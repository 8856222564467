//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019

import { cie2hex } from "../component/ColorPicker/utilities";

//----------------------------------------------------------------------------------------------------------------
const slug                                        = require("slug");
slug.charmap[" "] = "_"

declare const window: any;

// Adding new Languages also requires changing of light_right_app.cpp (LRAppSettings::StringToAppLanguage())!
export enum APP_LANGUAGE{
  English = 0, 
  German  = 1,
  Spanish = 2,
  French  = 3,
  Italien = 4,
  Russian = 5,
  Czech   = 6,
}

export function AppLanguageToString(l: APP_LANGUAGE){
  return {
    [APP_LANGUAGE.English]: "en",
    [APP_LANGUAGE.German ]: "de",
    [APP_LANGUAGE.Spanish]: "es",
    [APP_LANGUAGE.French ]: "fr",
    [APP_LANGUAGE.Italien]: "it",
    [APP_LANGUAGE.Russian]: "ru",
    [APP_LANGUAGE.Czech  ]: "cz"
  }[l]
}

export function StringToAppLanguage(l: string){
  return {
    "en": APP_LANGUAGE.English,
    "de": APP_LANGUAGE.German ,
    "es": APP_LANGUAGE.Spanish,
    "fr": APP_LANGUAGE.French ,
    "it": APP_LANGUAGE.Italien,
    "ru": APP_LANGUAGE.Russian,
    "cz": APP_LANGUAGE.Czech  
  }[l]
}

export enum APP_THEME{
  Light = 0,
  Dark = 1
}

export function AppThemeToString(l: APP_THEME){
  return {
    [APP_THEME.Light]: "light",
    [APP_THEME.Dark ]: "dark"
  }[l]
}

export enum APP_VIEW{
  Default = 0,
  SingleView = 1
}

export function AppDisplaymodeToString(l: APP_VIEW){
  return {
    [APP_VIEW.Default]: "default",
    [APP_VIEW.SingleView ]: "singleview"
  }[l]
}



export function StringToAppTheme(l: string){
  return {
    "light": APP_THEME.Light,
    "dark": APP_THEME.Dark
  }[l]
}


export let MARGIN_FOOTER      = 40;

export let TOOL_SELECT_INDEX            = 0;
export let OBJECT_PROPERTIES_INDEX      = 1;
export let SCENE_TREE_INDEX             = 2;
export let NAVIGATION_CONTAINER_INDEX   = 3;
export let RENDERER_INDEX               = 4;

//Worksheet
export let DEFAULT_VIEW     = 0;
export let PRESETS_VIEW     = 1;
export let INVENTORY_VIEW   = 2;
export let STRUCTURE_VIEW   = 3;
export let SUPPORT_VIEW     = 4;
export let TRUCK_VIEW       = 5;
export let CASE_VIEW        = 7;
export let SUMMERY_VIEW     = 6;
export let GEL_VIEW         = 8;
export let Electrical_VIEW  = 9;
export let RACK_VIEW        = 10;
export let CONNECTION_VIEW  = 11;
export let STRUCTURAL_CALCULATION  = 12;
export let COUNT_WORKSHEHET_VIEW_TYPE  = 13; // if you add more view types, make sure this stays last

export enum WORKSHEET_VIEW_TYPE{
  DEFAULT_VIEW     = 0,
  PRESETS_VIEW     = 1,
  INVENTORY_VIEW   = 2,
  STRUCTURE_VIEW   = 3,
  SUPPORT_VIEW     = 4,
  TRUCK_VIEW       = 5,
  CASE_VIEW        = 7,
  SUMMERY_VIEW     = 6,
  GEL_VIEW         = 8,
  Electrical_VIEW  = 9,
  RACK_VIEW        = 10,
  CONNECTION_VIEW  = 11,
  STRUCTURAL_CALCULATION  = 12,
  COUNT_WORKSHEHET_VIEW_TYPE  = 13 // if you add more view types, make sure this stays last
}

export let NOTES_VIEW       = 100;

// Inventroy Object Types
export let INVENTORY_CONTAINER_TYPE_TRUCK        = 0;
export let INVENTORY_CONTAINER_TYPE_RACK         = 1;
export let INVENTORY_CONTAINER_TYPE_CASE         = 2;

//Hoist Supporting
export let HOIST_REPORT_ANTWAYS = 0;
export let HOIST_REPORT_RECWAYS = 1;
export let HOIST_REPORT_YSORTING = 2;

export enum HOIST_REPORT_ENUM{
  ANTWAYS = 0,
  RECWAYS = 1,
  YSORTING = 2
}

export enum PROPERTY_BASED_FILTER_MODE{
  // For Numbers (requires setting of NumberValue)
  IsValue         ,  // ==
  IsNotValue      ,  // !=
  RangeValue      ,  // From <= x <= To   (requires setting of RangeMin && RangeMax)
  SmallerThan     ,  // <
  SmallerEqualThan,  // <=
  LargerThan      ,  // >
  LargerEqualThan ,  // >=

  // For Strings (requires setting of StringValue)
  IsString        ,  // ==
  IsNotString     ,  // !=
  Includes        ,  // value.includes(StringValue)
  Excludes        ,  // !value.includes(StringValue)  
  StartsWith      ,  // value.startsWith(StringValue)
  EndsWith        ,  // value.endsWith(StringValue)
}

//Data Network
export let ABSOLUTE_VIEW     = 0;
export let UNIVERSE_VIEW     = 1;

export let OBJECT           = "Object";
export let PRESET           = "Preset";
export let WORKSHEET        = "Worksheet";
export let LAYER            = "Layer";
export let CLASS            = "Class";
export let TIMEPHASE        = "TimePhase";
export let TIMEPHASECHANGE  = "TimePhaseChange";

export let NAME             = "Name";
export let PARENT           = "Parent";
export let OFFSET           = "Offset";
export let SCALE            = "Scale";
export let QUAT             = "Quat";
export let DIMMER           = "Dimmer";
export let LIGHT_COLOR      = "LightColor";
export let LOCKED           = "Locked";
export let VISIBLE          = "Visible";
export let ORDER            = "Order";
export let TURNOFF          = "TurnOff";
export let TURNON           = "TurnOn";
export let PARTNUMBER       = "PartNumber";
export let USER             = "User";
export let TIMEOFFSET       = "TimeOffset";
export let SUPPLIER         = "Supplier";

export let RESPONSIVE_PHONE        = 768;

export const kWireType_Input             = 1;
export const kWireType_Output            = 2;
export const kWireType_Generator         = 3;
export const kWireType_Consumer          = 4;
export const kWireType_Fuse              = 5;
export const kWireType_NetworkProvider   = 6;
export const kWireType_NetworkInput      = 7;
export const kWireType_NetworkOutput     = 8;
export const kWireType_NetworkInOut   = 9;
export const kWireType_HotpatchInput   = 10;
export const kWireType_HotpatchOutput  = 11;

export enum kWireType {
  Input = 1,
  Output = 2,
  Generator = 3,
  Consumer = 4,
  Fuse = 5,
  NetworkProvider = 6,
  NetworkInput = 7,
  NetworkOutput = 8,
  NetworkInOut = 9,
  HotpatchInput = 10,
  HotpatchOutput = 11,
}

export const ELECTRICAL_OBJECT_Generator    = 0;
export const ELECTRICAL_OBJECT_Distributor  = 1;
export const ELECTRICAL_OBJECT_Consumer     = 2;
export const ELECTRICAL_OBJECT_Wire         = 3;
export const ELECTRICAL_OBJECT_Complete     = 4;
export const ELECTRICAL_OBJECT_Fuse         = 5;


export const ELECTRICAL_NODE_CORNER_RADIUS  = 10;
export const ELECTRICAL_NODE_MIN_WIDTH      = 250;
export const ELECTRICAL_NODE_MIN_HEIGHT     = 125;

export const SELECT_COLOR           = 0x20C4F4
export const LIGHT_SELECT_COLOR     = 0x62d5f7


// Global defines
export let EMPTY_UUID = "{00000000-0000-0000-0000-000000000000}";
export let DEFAULT_LAYER_UUID = "{00000000-0000-0000-0000-000001000000}";
export let DEFAULT_CLASS_UUID = "{00000000-0000-0000-0000-000002000000}";

export let MAGNET_Transparency = 0.3;

//eventually replace all to enum
export enum TOOL_MODE{
  Insert = "insert",
  Select = "select",
  Move   = "move",
  Scale  = "scale",
  Rotate = "rotate",
  Align  = "align",
  Orbit  = "orbit",
  Measure = "measure",
  Mirror = "mirror",
  Camera = "camera",
  Patch = "patch",
}
export let TOOL_MODE_Insert = "insert";
export let TOOL_MODE_Select = "select";
export let TOOL_MODE_Move   = "move";
export let TOOL_MODE_Scale  = "scale";
export let TOOL_MODE_Rotate = "rotate";
export let TOOL_MODE_Align  = "align";
export let TOOL_MODE_Orbit  = "orbit";
export let TOOL_MODE_Measure = "measure";
export let TOOL_MODE_Mirror = "mirror";
export let TOOL_MODE_Camera = "camera";
export let TOOL_MODE_Patch = "patch";

export enum TOOL_MODE_INSERT{
  Single,
  Line,
  Array,
  SingleAlign,
  Rectangle
}

export let TOOL_MODE_Insert_Single = 0;
export let TOOL_MODE_Insert_Line   = 1;
export let TOOL_MODE_Insert_Array  = 2;
export let TOOL_MODE_Insert_SingleAlign = 3;

export enum TEXTURE_TYPE {
  PDF = -1,
  PNG = 0,
  JPEG = 1,
  SVG = 2
}

export enum RESOURCE_TYPE{
  AssemblyGroup     = 0,
  FixtureType       = 1,
  Mesh              = 2,
  SymbolDef         = 3,
  Magnet            = 4,
  Electrical        = 5,
  Inventory         = 6,
  Structure         = 7,
  Support           = 8,
  Audio             = 9,
  Dimension         = 10,
  Origin            = 11,
  PrintLabel        = 12,
  CalculationResult = 13,
  Connection        = 14,
  Curtain           = 15,
  PrintLabelField   = 16,
  Polygon           = 17,
  Load              = 18,
  TrussCross        = 19,
  FEMGeometry       = 20,
  Texture           = 21,
  TextObject        = 22,
  CablePath         = 23,
  Dock              = 24,
}

export let RESOURCE_TYPE_AssemblyGroup  = 0;
export let RESOURCE_TYPE_FixtureType    = 1;
export let RESOURCE_TYPE_Mesh           = 2;
export let RESOURCE_TYPE_SymbolDef      = 3;
export let RESOURCE_TYPE_Magnet         = 4;
export let RESOURCE_TYPE_Electrical     = 5;
export let RESOURCE_TYPE_Inventory      = 6;
export let RESOURCE_TYPE_Structure      = 7;
export let RESOURCE_TYPE_Support        = 8;
export let RESOURCE_TYPE_Audio          = 9;
export let RESOURCE_TYPE_Dimension      = 10;
export let RESOURCE_TYPE_Origin         = 11;
export let RESOURCE_TYPE_PrintLabel     = 12;
export let RESOURCE_TYPE_CalculationResult= 13;
export let RESOURCE_TYPE_Connection      = 14;
export let RESOURCE_TYPE_Curtain         = 15;
export let RESOURCE_TYPE_PrintLabelField = 16;

export let RESOURCE_TYPE_Polygon         = 17;
export let RESOURCE_TYPE_Load         = 18;
export let RESOURCE_TYPE_TrussCross         = 19;
export let RESOURCE_TYPE_TextObject         = 22;
export let RESOURCE_TYPE_CablePath         = 23;
export let RESOURCE_TYPE_Dock         = 24;

export let CONNECTION_TYPE_Rope           = 0;
export let CONNECTION_TYPE_TrussCross     = 1;
export let CONNECTION_TYPE_Drop           = 2;
export let CONNECTION_TYPE_Pipe           = 3;
export let CONNECTION_TYPE_Bridle2         = 4;
export let CONNECTION_TYPE_Bridle3         = 5;
export let CONNECTION_TYPE_Bridle4         = 8;

export enum SELECTION_MODE{
  Single    = 0,
  SameName  = 1,
  SameType  = 2,
  Box       = 3,
  SameClass = 4,
  SameLayer = 5,
  SameProperty = 6,
}
export let SELECT_Single    = 0;
export let SELECT_SameName  = 1;
export let SELECT_SameType  = 2;
export let SELECT_Box       = 3;
export let SELECT_SameClass = 4;
export let SELECT_SameLayer = 5;
export let SELECT_SameProperty = 6;

export enum SELECTION_MODE_GROUP{
  Inside = 0,
  Outside
}
export let SELECT_Group_Inside   = 0;
export let SELECT_Group_Outside  = 1;

export enum SETTING_MODE{
  Default = 0,
  Increment,
  AlignFirst,
  AlignLast
}
export let SETTING_Default      = 0;
export let SETTING_Increment    = 1;
export let SETTING_AlignFirst   = 2;
export let SETTING_AlignLast    = 3;

export let OBJECT_TYPE_SceneObject  = 0;
export let OBJECT_TYPE_Fixture      = 1;
export let OBJECT_TYPE_Truss        = 2;
export let OBJECT_TYPE_Dimension    = 3;

export enum ORBIT_MODE{
  Pan = 0
}
export let ORBIT_Pan                = 0;

export enum ALIGN_MODE{
  Light = 0,
  AssemblyOrigin
}
export let ALIGN_Light              = 0;
export let ALIGN_AssemblyOrigin     = 1;

export enum TRANSFORM_MODE{
  Local = 0,
  Global = 1
}
export let TRANSFORM_Local  = 0;
export let TRANSFORM_Global = 1;
export let TRANSFORM_SCALE = 2;

export let GEOMETRY_OBJECT_TYPE_Normal      = 0;
export let GEOMETRY_OBJECT_TYPE_Electrical  = 1;
export let GEOMETRY_OBJECT_TYPE_Inventory   = 2;
export let GEOMETRY_OBJECT_TYPE_Structure   = 3;
export let GEOMETRY_OBJECT_TYPE_Support     = 4;
export let GEOMETRY_OBJECT_TYPE_Audio       = 5;
export let GEOMETRY_OBJECT_TYPE_Dimension   = 6;
export let GEOMETRY_OBJECT_TYPE_Origin      = 7;
export let GEOMETRY_OBJECT_TYPE_InfluenceLine= 8;
export let GEOMETRY_OBJECT_TYPE_FemGeometry = 9;
export let GEOMETRY_OBJECT_TYPE_Curtain = 10;
export let GEOMETRY_OBJECT_TYPE_PrintLabelField = 11;
export let GEOMETRY_OBJECT_TYPE_Polygon = 12;
export let GEOMETRY_OBJECT_TYPE_Load = 13;
export let GEOMETRY_OBJECT_TYPE_CableDock = 14;

export enum GEOMETRY_OBJECT_TYPE {
  Normal = 0,
  Electrical = 1,
  Inventory = 2,
  Structure = 3,
  Support = 4,
  Audio = 5,
  Dimension = 6,
  Origin = 7,
  InfluenceLine = 8,
  FemGeometry = 9,
  Curtain = 10,
  PrintLabelField = 11,
  Polygon = 12,
  Load = 13,
  RendererView = 14
}

export enum PRINT_LABEL_FIELD_TYPE{
  StaticText  = 0,
  DynamicText = 1,
  QRCode      = 2,
  Picture     = 3,
  ColorCode   = 4,
  SymbolGeometry = 5,
}

export let PRINT_LABEL_FIELD_TYPE_ITERATOR = Object.values(PRINT_LABEL_FIELD_TYPE).filter(i => typeof i === "string") as PRINT_LABEL_FIELD_TYPE[]


export enum GEOMETRY_POLYGON_VIEW_TYPE{
  None            = 0,
  Renderer2D      = 1,
  Renderer3D      = 2,
  ElectricalGraph = 3,
  DataGraph       = 4,
}

export let GEOMETRY_POLYGON_VIEW_TYPE_ITERATOR = Object.values(GEOMETRY_POLYGON_VIEW_TYPE).filter(i => typeof i === "string") as GEOMETRY_POLYGON_VIEW_TYPE[]


export enum DYNAMIC_ITEM_TYPE {
  NO_DYNAMIC_ITEM,
  FIRST_ITEM_IN_GROUP,
  CONCAT_ALL_ITEMS,
  GET_FROM_ID
}

export enum POLYGON_LINE_TYPE{
  Line,
  Dash,
  Point,
  PointDash,
  None
}

export let POLYGON_LINE_TYPE_ITERATOR = Object.values(POLYGON_LINE_TYPE).filter(i => typeof i === "string") as POLYGON_LINE_TYPE[]

export let GEOMETRY_SUPPORT_TYPE_Rope       = 0;
export let GEOMETRY_SUPPORT_TYPE_Ground     = 1;
export let GEOMETRY_SUPPORT_TYPE_HouseRigging     = 2;



export const SupportTypeOptions = [
  {
    text: "Rope",
    value: GEOMETRY_SUPPORT_TYPE_Rope
  },
  {
    text: "Ground",
    value: GEOMETRY_SUPPORT_TYPE_Ground
  }
]

export enum GEOMETRY_SUPPORT_TYPE{
  Rope = 0,
  Ground = 1,
  HouseRigging = 2
}

export let GEOMETRY_FEM_GEOMETRY_TYPE_Support       = 0;
export let GEOMETRY_FEM_GEOMETRY_TYPE_Frame     = 1;
export let GEOMETRY_FEM_GEOMETRY_TYPE_Node     = 2;
export let GEOMETRY_FEM_GEOMETRY_TYPE_Load = 3;

export let GEOMETRY_StructureType_CenterLineBased   = 0;
export let GEOMETRY_StructureType_Detail            = 1;

export let GEOMETRY_CrossSectionType_TemplateCrossSection  = 0;
export let GEOMETRY_CrossSectionType_Tube      = 1;
export let GEOMETRY_CrossSectionType_Rectangle      = 2;

export let GEOMETRY_TRANSFORM_TYPE_TranslationX = 0;
export let GEOMETRY_TRANSFORM_TYPE_TranslationY = 1;
export let GEOMETRY_TRANSFORM_TYPE_TranslationZ = 2;
export let GEOMETRY_TRANSFORM_TYPE_RotationX    = 3;
export let GEOMETRY_TRANSFORM_TYPE_RotationY    = 4;
export let GEOMETRY_TRANSFORM_TYPE_RotationZ    = 5;

export let ARRAY_MODIFY_Line            = 0;
export let ARRAY_MODIFY_Rect            = 1;
export let ARRAY_MODIFY_Circle          = 2;

export enum EDIT_MODE{
  Scene = 0,
  Geometry
}
export let EDIT_Scene           = 0;
export let EDIT_Geometry        = 1;

export let RENDERER_VIEW_FRONT          = 0;
export let RENDERER_VIEW_BACK           = 1;
export let RENDERER_VIEW_LEFT           = 2;
export let RENDERER_VIEW_RIGHT          = 3;
export let RENDERER_VIEW_TOP            = 4;
export let RENDERER_VIEW_BOTTOM         = 5;
export let RENDERER_VIEW_FRONT_LEFT     = 6;
export let RENDERER_VIEW_FRONT_RIGHT    = 7;
export let RENDERER_VIEW_REAR_LEFT      = 8;
export let RENDERER_VIEW_REAR_RIGHT     = 9;

export let OBJECT_FIELD           = 40;
export let RACK_FIELD             = 41;
export let CASE_FIELD             = 42;
export let COLOR_FIELD            = 43;
export let VECTOR_MAGNITUDE_FIELD = 44;
export let GOBO_FIELD             = 45;

export enum CUSTOM_CELL_FUNCTION_TYPE
{
  OBJECT_FIELD = 40,
  RACK_FIELD = 41,
  CASE_FIELD = 42,
  COLOR_FIELD = 43,
  VECTOR_MAGNITUDE_FIELD = 44,
  GOBO_FIELD = 45,
}

export let BASE_UNIT_NUMBER      = 0;
export let BASE_UNIT_LENGTH      = 1;
export let BASE_UNIT_WEIGHT      = 2;
export let BASE_UNIT_VOLUME      = 3;
export let BASE_UNIT_AREA        = 4;
export let BASE_UNIT_ONE_BASED   = 5;
export let BASE_UNIT_DATE        = 6;
export let BASE_UNIT_ANGLE       = 7;
export let BASE_UNIT_VOLTAGE     = 8;
export let BASE_UNIT_POWER       = 9;
export let BASE_UNIT_COLOR       = 10;
export let BASE_UNIT_FORCE       = 11;

export let BASE_UNIT_ZERO_ONE    = 12;
export let BASE_UNIT_PAGE_LENGTH = 13;
export let BASE_UNIT_TIME_CODE   = 14;
export let BASE_UNIT_STRING      = 15;
export let BASE_UNIT_AREA_MOMENT_OF_INERTIA = 16;
export let BASE_UNIT_TORQUE      = 17;
export let BASE_UNIT_PRESSURE    = 18;
export let BASE_UNIT_DENSITY     = 19;
export let BASE_UNIT_POISSON_EFFECT = 20;
export let BASE_UNIT_FORCE_PER_DISTANCE  = 21;
export let BASE_UNIT_MOMENT_PER_DISTANCE  = 22;
export let BASE_UNIT_COLOR_OBJECT = 23;
export let BASE_UNIT_PERCENT = 24;
export let BASE_UNIT_AMPERE     = 25;
export let BASE_UNIT_BOOLEAN    = 26;
export let BASE_UNIT_WEIGHT_PER_DISTANCE      = 27;
export let BASE_UNIT_SELECTABLE = 28;
export let BASE_UNIT_FORCE_PER_AREA = 30;
export let BASE_UNIT_VELOCITY = 31;

export enum BASE_UNIT {
  NUMBER = 0,
  LENGTH = 1,
  WEIGHT = 2,
  VOLUME = 3,
  AREA = 4,
  ONE_BASED = 5,
  DATE = 6,
  ANGLE = 7,
  VOLTAGE = 8,
  POWER = 9,
  COLOR = 10,
  FORCE = 11,

  ZERO_ONE = 12,
  PAGE_LENGTH = 13,
  TIME_CODE = 14,
  STRING = 15,
  AREA_MOMENT_OF_INERTIA = 16,
  TORQUE = 17,
  PRESSURE = 18,
  DENSITY = 19,
  POISSON_EFFECT = 20,
  FORCE_PER_DISTANCE = 21,
  MOMENT_PER_DISTANCE = 22,
  COLOR_OBJECT = 23,
  PERCENT = 24,
  AMPERE = 25,
  BOOLEAN = 26,
  WEIGHT_PER_DISTANCE = 27,
  SELECTABLE = 28,
  FORCE_PER_AREA = 29,
  VELOCITY = 30
}

export let UNIT_KILO_NEWTON      = 0;
export let UNIT_POUNDFORCE           = 1;

export let UNIT_Milimeter        = 0;
export let UNIT_Centimeter       = 1;
export let UNIT_Meter            = 2;
export let UNIT_Feet             = 3;
export let UNIT_Inches           = 4;
export let UNIT_FeetInches       = 5;

export let UNIT_ZERO_ONE_ZeroOne = 0;
export let UNIT_ZERO_ONE_Percent = 1;

export let UNIT_GrammPerMillimeter           = 0;
export let UNIT_KilogrammsPerMillimeter       = 1;
export let UNIT_PoundPerFeet       = 2;

export let UNIT_Gramms           = 0;
export let UNIT_Kilogramms       = 1;
export let UNIT_Pounds           = 2;
export let UNIT_ShortTons        = 3;

export let UNIT_SqMilimeter      = 0;
export let UNIT_SqCentimeter     = 1;
export let UNIT_SqMeter          = 2;
export let UNIT_SqInch          = 3;
export let UNIT_SqFeet          = 4;

export let UNIT_CubMilimeter     = 0;
export let UNIT_CubCentimeter    = 1;
export let UNIT_CubMeter         = 2;

export let UNIT_KILO_NEWTON_MILIMETER = 0;
export let UNIT_KILO_NEWTON_METER = 1;
export let UNIT_KILO_POUND_FORCE_FEET = 2;

export let UNIT_KILO_NEWTON_METER_PER_DEG = 1;
export let UNIT_KILO_NEWTON_MILIMETER_PER_DEG = 0;
export let UNIT_KILO_NEWTON_METER_PER_RAD = 3;
export let UNIT_KILO_NEWTON_MILIMETER_PER_RAD = 2;

export let UNIT_KILO_NEWTON_PER_MILIMETER = 0;
export let UNIT_KILO_NEWTON_PER_METER = 1;
export let UNIT_POUNDFORCE_PER_FEET = 2;

export let UNIT_MEGA_PASCAL = 0;


export let UNIT_KILO_NEWTON_PER_SQUARE_MILIMETER = 0;
export let UNIT_KILO_NEWTON_PER_SQUARE_CENTIMETER = 1;
export let UNIT_KILO_NEWTON_PER_SQUARE_METER = 2;
export let UNIT_POUNDFORCE_PER_SQUARE_FEET = 3;

export let UNIT_MILIMETER_PER_SECOND = 0;
export let UNIT_METER_PER_SECOND = 1;
export let UNIT_KILOMETER_PER_HOUR = 2;
export let UNIT_FEET_PER_SECOND = 3;
export let UNIT_MILES_PER_HOUR = 4;

export let UNIT_KILO_PER_CubMeter = 0;

export let UNIT_DEGREE = 0;

export let UNIT_VOLT = 0;


export let UNIT_AMPERE = 0;

export let UNIT_WATT = 0;
export let UNIT_KILO_WATT = 1;

export let UNIT_HMS = 0;

export let UNIT_POISSON = 0;

export let UNIT_CM4 = 0;

export let UNIT_ONE_BASED = 0

export let UNIT_FRAMES = 0
export let UNIT_SECONDS = 1
export let UNIT_MINUTES = 2
export let UNIT_HOURS = 3



export const TRUSS_INSERT_ALIGNMENT_TOPLEFT_LEFT = 0;
export const TRUSS_INSERT_ALIGNMENT_TOPLEFT_UP = 1;
export const TRUSS_INSERT_ALIGNMENT_TOPRIGHT_UP = 2;
export const TRUSS_INSERT_ALIGNMENT_TOPRIGHT_RIGHT = 3;
export const TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_RIGHT = 4;
export const TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_DOWN = 5;
export const TRUSS_INSERT_ALIGNMENT_BOTTOMLEFT_DOWN = 6;
export const TRUSS_INSERT_ALIGNMENT_BOTTOMLEFT_LEFT = 7;
export const TRUSS_INSERT_ALIGNMENT_CENTER = 8;
export const TRUSS_INSERT_ALIGNMENT_CENTER_UP = 9;
export const TRUSS_INSERT_ALIGNMENT_CENTER_DOWN = 10;
export const TRUSS_INSERT_ALIGNMENT_CENTER_RIGHT = 11;
export const TRUSS_INSERT_ALIGNMENT_CENTER_LEFT = 12;
export const TRUSS_INSERT_ALIGNMENT_LAST = TRUSS_INSERT_ALIGNMENT_CENTER_LEFT;
export const Feet_Inch_Regex = /(\d+\.?\d*'\s*)?(\d+\.?\d*(?:"|'')\s*)(\d+\/\d+)?|(\d+\.?\d*'\s*)(\d+\.?\d*(?:"|'')\s*)?(\d+\/\d+)?/g ; 


// defines pairs of operations for from/to core value conversion
// each pair therefore is made of two functions that undo eachother :
// "up" describes converting to core values and receives (view_val, conv_rate)
// "down" describes converting from core values and receives (core_val, conv_rate)
const OPERATION_PAIRS = {
  "-+": { up: (a, b) => a - b, down: (a, b) => a + b},
  "*/": { up: (a, b) => a * b, down: (a, b) => a / b},
  "angle": { up: (a, b) => degToRad(a), down: (a, b) => radToDeg(a)},
  "ftin": { up: (a, b) => a * b, down: (a, b) => a / b,
          toString: (totalInches) =>
          {
            if(typeof totalInches !== "number") return totalInches
            const inchesPerFoot = 12;
            const fractionDenominator = 8; // Adjust for precision

            // Handle negative totalInches 
            const sign = totalInches < 0 ? '-' : '';
            totalInches = Math.abs(totalInches); 
        
            let feet = Math.floor(totalInches / inchesPerFoot);
            let fractionalInches = totalInches - (feet * inchesPerFoot);
        
            let numerator = Math.round(fractionalInches * fractionDenominator);
            let denominator = fractionDenominator;
        
            // Reducing the fraction to simplest form
            while (numerator % 2 === 0 && denominator % 2 === 0) {
                numerator /= 2;
                denominator /= 2;
            }
        
            // Displaying the result
            let result = `${sign}${feet}' ${Math.floor(numerator / denominator)}"`;
            if (numerator % denominator !== 0) {
                result += ` ${numerator % denominator}/${denominator}`;
            }
        
            return result;
          },
          fromString: (input) =>
          {
            let totalInches = 0;  
            input = input.replace(Feet_Inch_Regex , (match, ...arr) => 
            {
              let feets = (arr[0] ?? arr[3]) ?? 0; 
              let inches =( arr[1] ?? arr[4]) ?? 0;
              let fraction = (arr[2] ?? arr[5]) ?? 0;

              let feetNum     = extractNumber(feets);
              let inchesNum   = extractNumber(inches);
              let fractionNum = eval(fraction); 

              if(feets && !inches && fraction)
              {
                let totalFeets = feetNum + fractionNum; 
                totalInches = totalFeets*12;
              }
              else{
                totalInches = feetNum*12 + inchesNum + fractionNum;
              }
              return totalInches
            })  
            
            return input; 
          }
        }
}

/*  UNITS: An object that manages our unit data
    {
      <some base unit id>: {
        units: [ 
          { 
            id <some unit id>, 
            str:<string representation of the unit>,
            conversion_rate: <conversion rate from/to core values>,
            conversion_operation: {
              up: <operation for converting to core values and receives (view_val, conv_rate)>
              down: <operation for converting from core values and receives (core_val, conv_rate)>
            }
          } 
        ]
        referTo: <refer to another base unit by id, so if two base units use 
                  the same units they only have to be maintained in one place>
      }
    }
*/ 
export const UNITS = {
  [BASE_UNIT_NUMBER]: {
    units: [ /* has no units */ ]
  },
  [BASE_UNIT_LENGTH]: {
    units: [
      {id: UNIT_Milimeter, str: "mm", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_Centimeter, str: "cm", conversion_rate: 10, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_Meter, str: "m", conversion_rate: 1000, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_Feet, str: "ft", conversion_rate: 304.8, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_Inches, str: "in", conversion_rate: 25.4, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_FeetInches, str: "ftin", conversion_rate: 25.4, conversion_operation: OPERATION_PAIRS["ftin"]},
    ]
  },
  [BASE_UNIT_WEIGHT]: {
    units: [
      {id: UNIT_Gramms, str: "g", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_Kilogramms, str: "kg", conversion_rate: 1000, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_Pounds, str: "lbs", conversion_rate: 453.592, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_ShortTons, str: "tn. sh.", conversion_rate: 907185, conversion_operation: OPERATION_PAIRS["*/"]}, // American / short ton !!
    ]
  },
  [BASE_UNIT_WEIGHT_PER_DISTANCE]: {
    units: [
      {id: UNIT_GrammPerMillimeter, str: "g/mm", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KilogrammsPerMillimeter, str: "kg/m", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_PoundPerFeet, str: "lbs/ft", conversion_rate: 1.48816, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_VOLUME]: {
    units: [
      {id: UNIT_CubMilimeter, str: "mm^3", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_CubCentimeter, str: "cm^3", conversion_rate: 1000, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_CubMeter, str: "m^3", conversion_rate: 1000000000, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_AREA]: {
    units: [
      {id: UNIT_SqMilimeter, str: "mm^2", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_SqCentimeter, str: "cm^2", conversion_rate: 100, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_SqMeter, str: "m^2", conversion_rate: 1000000, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_SqInch, str: "in^2", conversion_rate: 645.16, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_SqFeet, str: "ft^2", conversion_rate: 92903.04, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_ONE_BASED]: {
    units: [ {id: UNIT_ONE_BASED, str: "", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["-+"]} ]
  },
  [BASE_UNIT_DATE]: {
    units: []
  },
  [BASE_UNIT_ANGLE]: {
    units: [
      {id: UNIT_DEGREE, str: "°", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["angle"]}, // conversion rate is not used here
    ]
  },
  [BASE_UNIT_VOLTAGE]: {
    units: [
      {id: UNIT_VOLT, str: "V"},
    ]
  },
  [BASE_UNIT_AMPERE]: {
    units: [
      {id: UNIT_AMPERE, str: "A"},
    ]
  },
  [BASE_UNIT_POWER]: {
    units: [
      {id: UNIT_WATT, str: "W", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KILO_WATT, str: "kW",conversion_rate: 1000, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_COLOR]: {
    units: [ /* has no units */ ]
  },
  [BASE_UNIT_FORCE]: {
    units: [
      {id: UNIT_KILO_NEWTON, str: "kN", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_POUNDFORCE, str: "lbf", conversion_rate: 1/224.809, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_ZERO_ONE]: {
    units: [ 
      {id: UNIT_ZERO_ONE_ZeroOne, str: ".0", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_ZERO_ONE_Percent, str: "%", conversion_rate: .01, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_PAGE_LENGTH]: {
    referTo: BASE_UNIT_LENGTH,
  },
  [BASE_UNIT_TIME_CODE]: {
    units: [
      {id: UNIT_FRAMES, str: "f", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_SECONDS, str: "s", conversion_rate: 30, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_MINUTES, str: "min", conversion_rate: 1800, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_HOURS, str: "h", conversion_rate: 108000, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_STRING]: {
    units: [ /* has no units */ ]
  },
  [BASE_UNIT_AREA_MOMENT_OF_INERTIA]: {
    units: [
      {id: UNIT_CM4, str: "cm^4"},
    ]
  },
  [BASE_UNIT_TORQUE]: {
    units: [
      {id: UNIT_KILO_NEWTON_MILIMETER, str:" kNmm", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KILO_NEWTON_METER, str: "kNm", conversion_rate: 1000, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KILO_POUND_FORCE_FEET, str: "lbfft", conversion_rate: 1.355818, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_PRESSURE]: {
    units: [
      {id: UNIT_MEGA_PASCAL, str: "MPa"},
    ]
  },
  [BASE_UNIT_FORCE_PER_AREA]: {
    units: [
      {id: UNIT_KILO_NEWTON_PER_SQUARE_MILIMETER, str: "kN/mm2"},
      {id: UNIT_KILO_NEWTON_PER_SQUARE_CENTIMETER, str: "kN/cm2", conversion_rate: 0.01, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KILO_NEWTON_PER_SQUARE_METER, str: "kN/m2", conversion_rate: 0.000001, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_POUNDFORCE_PER_SQUARE_FEET, str: "lbf/ft2", conversion_rate: 0.0478803, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_VELOCITY]: {
    units: [
      {id: UNIT_MILIMETER_PER_SECOND, str: "mm/s"},
      {id: UNIT_METER_PER_SECOND, str: "m/s", conversion_rate: 1/0.001, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KILOMETER_PER_HOUR, str: "km/h", conversion_rate: 1/0.0036, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_FEET_PER_SECOND, str: "ft/s", conversion_rate: 1/0.00328084, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_MILES_PER_HOUR, str: "MPH", conversion_rate: 1/0.00223694, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  
  [BASE_UNIT_DENSITY]: {
    units: [
      {id: UNIT_KILO_PER_CubMeter, str: "kg/m3"},
    ]
  },
  [BASE_UNIT_POISSON_EFFECT]: {
    units: [
      {id: UNIT_POISSON, str: "v"},
    ]
  },
  [BASE_UNIT_FORCE_PER_DISTANCE]: {
    units: [
      {id: UNIT_KILO_NEWTON_PER_MILIMETER, str: "kN/mm", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_KILO_NEWTON_PER_METER, str: "kN/m", conversion_rate: .001, conversion_operation: OPERATION_PAIRS["*/"]},
      {id: UNIT_POUNDFORCE_PER_FEET, str: "lbf/ft", conversion_rate: 1.4594e-5, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_MOMENT_PER_DISTANCE]: {
    units: [
      {id:UNIT_KILO_NEWTON_MILIMETER_PER_DEG, str: "kNmm/°", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
      {id:UNIT_KILO_NEWTON_METER_PER_DEG, str: "kNm/°", conversion_rate: 1000, conversion_operation: OPERATION_PAIRS["*/"]},
      {id:UNIT_KILO_NEWTON_METER_PER_RAD, str: "kNm/r", conversion_rate: 1/57.29577951308232286 * 1000, conversion_operation: OPERATION_PAIRS["*/"]}, // 1000 * 57.29577951308232286
      {id:UNIT_KILO_NEWTON_MILIMETER_PER_RAD, str: "kNmm/r", conversion_rate: 1/57.29577951308232286, conversion_operation: OPERATION_PAIRS["*/"]},
    ]
  },
  [BASE_UNIT_COLOR_OBJECT]: {
    units: [ /* has no units */ ]
  },
  [BASE_UNIT_SELECTABLE]: {
    units: [ /* has no units */ ]
  },
  [BASE_UNIT_BOOLEAN]: {
    units: [ /* has no units */ ]
  },
  [BASE_UNIT_PERCENT]: {
    units: [       {id:0, str: "%", conversion_rate: 1, conversion_operation: OPERATION_PAIRS["*/"]},
  ]
  },
}

export let OBJECT_PROPERTIES_TAB_ID = 0;
export let SCENE_TREE_TAB_ID        = 1;
export let RENDERER_TAB_ID          = 2;
export let NAVIGATION_TAB_ID        = 3;

export let CHILD_TAB_ID             = 4;

export let LABEL_OBJECT_FILTER_OBJECT       = 0;
export let LABEL_OBJECT_FILTER_RACK         = 1;
export let LABEL_OBJECT_FILTER_CASE         = 2;

export let SORT_LOW_HIGH  = 0;
export let SORT_HIGH_LOW  = 1;

export let TASK_STATE_NONE     = 'NO_STATUS';
export let TASK_STATE_PROGRESS = 'IN_PROGRESS';
export let TASK_STATE_FINISHED = 'FINISHED';


export let BEAM_DISPLAY_MODE_BEAUTIFUL = 0;
export let BEAM_DISPLAY_MODE_FAST      = 1;
export let BEAM_DISPLAY_MODE_OFF       = 2;

// enum ESignalType
export const kSignalType_Power       = 1;
export const kSignalType_DMX512      = 2;
export const kSignalType_Network     = 3;
export const kSignalType_AES         = 4;


//EWorkloadColoringMode
export let EWorkloadColoringMode_Threshold = 0;
export let EWorkloadColoringMode_Gradient  = 1;

export function logToBase(val: number, base: number) {
  return Math.log(val) / Math.log(base);
}

export function radToDeg(value)
{
  let returnValue = ((value / Math.PI) * 180) ;
  if(isNaN(returnValue))  { return value; }
  else                    { return returnValue; }
}

export function degToRad(value)
{

  let returnValue = ((value / 180) * Math.PI);
  if(isNaN(returnValue))  { return value; }
  else                    { return returnValue; }
}

export function IsElectronContext()
{
  return !!window.PA_IsElectronContext || !!window.ElectronWebsocketMode;
}

export function IsWebsocketMode()
{
  return  !!window.ElectronWebsocketMode;
}

export function IsDarkTheme()
{
  return window.window.localStorage.getItem("theme") === "dark";
}


export function ForceOneWindowRouter()
{
  return window.window.localStorage.getItem("router") === "singleview";
}


export function IsVectorworksContext()
{
  return !!window.IsVectorworksContext;
}


export function IsRunningAsBrowser()
{
  return ! (IsElectronContext() || window.IsVectorworksContext || window.IsIOS || window.Android|| window.ElectronWebsocketMode || window.IsSketchup)
}

export function GetPayLoadColor(node, alpha = 0.1) 
{
  let payload    = node.Payload;
  if(payload === 0)
  {
    return "lightgrey"
  }

  let maxPayload = node.MaxPayload;
  let interp     = payload / maxPayload;
  interp = Math.max(0.0, Math.min(interp, 1.0)) * 128;

  let color = `hsla(${128 - interp}, 100%, 50%,${alpha})`;
  
  return color;
}

export function GetPayLoadColor_2(payload, maxPayload, alpha = 0.3)
{
  let interp = payload / maxPayload
  interp = Math.max(0.0, Math.min(interp, 1.0)) * 128;

  let color = `hsla(${128 - interp}, 100%, 50%,${alpha})`;
  return color;
}

export function escapeRegExp(str)
{
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function charIsDigit(c) {
  return c >= '0' && c <= '9'
}

function matchesAdd(str)
{ 
  return str === "+"
}

function matchesThru(str)
{
  return str === "t" || str === "-" || str === "..." || str === "thru";
}

function generateIdsForAdd(argList, addIndex)
{
  let result: any[] = []

  let firstNumber = parseInt(argList[addIndex + 1])
  if (argList.length > addIndex + 3)
  {
    let op = argList[addIndex + 2]
    if (matchesThru(op))
    {
      result.push(...generateIdsForThru(argList, addIndex + 2))
    }
  }
  else if (!isNaN(firstNumber))
  {
    result.push(firstNumber)
  }
  return result
}

function generateIdsForThru(argList, thruIndex)
{
  let result: any[] = []

  let firstNumber = parseInt(argList[thruIndex - 1])
  let secondNumber = parseInt(argList[thruIndex + 1])
  for (let id = firstNumber; id <= secondNumber; id++)
  {
    result.push(id)
  }
  return result
}

// This code is a js copy from the core LightRightCommandLine::GenerateSelectIds
export function getIdsFromCommandString(str)
{
  if (str.length === 0) { return []}
  // Split the string
  let argList: any[] = []

  let currentStr = ""
  let first = true
  let currentlyDigit = true

  for (let i = 0; i < str.length; i++)
  {
    let currentChar = str[i]
    if (charIsDigit(currentChar))
    {
      if (!currentlyDigit)
      {
        argList.push(currentStr)
        currentStr = ""
      }
      currentStr += currentChar
      first = false
      currentlyDigit = true
    }
    else if (!first)
    {
      if (currentlyDigit)
      {
        argList.push(currentStr)
        currentStr = ""
      }
      currentStr += currentChar
      currentlyDigit = false;
    }
    else
    {
      return []
    }
  }
  argList.push(currentStr)

  if (argList.length === 1) { return [parseInt(argList[0])]}

  let result: any[] = []

  let findingFirst = true
  for (let i = 1; i < argList.length; i++)
  {
    if (findingFirst)
    {
      if (matchesThru(argList[i]))
      {
        let firstNumber = parseInt(argList[i - 1])
        let secondNumber = parseInt(argList[i + 1])
        for (let id = firstNumber; id <= secondNumber; id++)
        {
          result.push(id)
        }
        findingFirst = false
      }
      else if (matchesAdd(argList[i]))
      {
        if (i === 1)
        {
          let firstNumber = parseInt(argList[0])
          result.push(firstNumber)
          result.push(...generateIdsForAdd(argList, i))
        }
      }
      else
      {
        return result
      }
    }
    else if (matchesAdd(argList[i]))
    {
      result.push(...generateIdsForAdd(argList, i))
      findingFirst = false
    }
  }
  return result
}


const DefaultConnector = {
  SocketInfo:[{},{},{},{},{}]
}

export function GetConnectorInfoByName_Electrical(object) 
{
  if (!window.globalConnectors_Electrical) { return {} }
    let r = window.globalConnectors_Electrical[object]
    return r ? r : DefaultConnector
}

export function GetConnectorInfoByName_Data(object) 
{
  if (!window.globalConnectors_Electrical) { return {} }
    let r = window.globalConnectors_Data[object]
    return r ? r : DefaultConnector
}

export function GetConnectorInfoByName(object) 
{
  if (!window.globalConnectors_Electrical) { return {} }
    let r = window.globalConnectors[object]
    return r ? r : DefaultConnector
}

// get dots per millimeter
export function getDPMM()  
{
  // make sure to use a 1 by 1 mm div with no padding etc
  let div = document.createElement("div")
  div.style.height = "10mm"
  div.style.width = "10mm"
  div.style.top = "-100%"
  div.style.left = "-100%"
  div.style.position = "absolute"
  div.style.border = "none !important"
  div.style.padding = "0"
  div.style.margin = "0"

  document.body.appendChild(div)

  let result =  div.offsetHeight / 10

  document.body.removeChild(div)

  return result
}

function preventDefault(e)
{
  e.preventDefault()
}

export function disableScrolling()
{
  window.addEventListener("wheel", preventDefault, {passive: false})
}

export function enableScrolling()
{
  window.removeEventListener("wheel", preventDefault, {passive: false})
}

export function getLocale()
{
  let lang = "en";
  if (navigator.language.startsWith("de-")) { lang = "de"; }
  if (navigator.language.startsWith("es-")) { lang = "es"; }
  if (navigator.language.startsWith("fr-")) { lang = "fr"; }
  if (navigator.language.startsWith("it-")) { lang = "it"; }
  if (navigator.language.startsWith("ru-")) { lang = "ru"; }
  return lang;
};

export function slugify(str)
{
    str = str.trim()
    return slug(str,{ mode: 'rfc3986'});
}

// from w3resource
export function ValidateEmail(mail) 
{
  return  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)
}


export let RiggingWorkspace = {
  Tools:
  {
      NewObject: true,
      Select: true,
      Move: true,
      Scale: true,
      Rotate: true,
      Mirror: true,
      Align: false,
      Orbit: true,
      Measure: true,
      Patch: false, 
  },
  Navigation:
  {
      Classes: true,
      Layers: true,
      SelectionGroups: true,
      Presets: false,
      TimePhases: false,
      Users: false,
      Worksheets: false,
      Tasks: false,
      SavedViews: false,
      ColorCodes: false,
      Departments: false,
      Cases: false,
      Racks: false,
      Containers: false,
      Errors: true,
      LoadGroups: true,
      LoadCombinations: true,
      PrintLabels: true,
      InfluenceLines: true,
  },
  Worksheet:
  {
     Properties: true,
     Inventory: true,
     Structures: true,
     Supports: true,
     Trucks: false,
     Cases: false,
     Racks: false,
     Gels: true,
     ElectricalOverview: false,
     Connections: true,
     Notes: false,
     Structural: true,
     Overview: false

  },
  ObjectInfo:
  {
      Note: true,
      RenderingOptions: true,
      GlobalPosition: true,
      TimeAndUser: false,
      Inventory: false,
      History: false,
      Magnet: false,
      Line: true,
      CustomTransform: true,
      LinkedGraphic: true,
      ElectricalProperties: false,
      FixtureData: false,
      FixtureDesign: false,
      FixtureType: false,
      ExtraElectricalInfo: false,
      WireProperties: false,
      Support:true,
      PrintLabelFieldProperties: true,
      PrintLabel: true,
      Structure: true,
      Load: true,
      SupportProperties: true,
      GeometryOrigin: true,
      Dimension: true,
      ObjectPath: true,
      InfluenceLine: true,
      Structural: true,
      Cables: false,
      ContainerSupport: false,
      InventoryGeometries: false,
  }
}

export let LightWorkspace = {
  Tools:
  {
      NewObject: true,
      Select: true,
      Move: true,
      Scale: true,
      Rotate: true,
      Mirror: true,
      Align: true,
      Orbit: true,
      Measure: true,
      Patch: true,
  },
  Navigation:
  {
      Classes: true,
      Layers: true,
      SelectionGroups: true,
      Presets: true,
      TimePhases: false,
      Users: false,
      Worksheets: true,
      Tasks: false,
      SavedViews: true,
      ColorCodes: true,
      Departments: false,
      Cases: false,
      Racks: false,
      Containers: false,
      Errors: false,
      LoadGroups: false,
      LoadCombinations: false,
      PrintLabels: true,
      InfluenceLines: false,
  },
  Worksheet:
  {
      Properties: true,
      Inventory: true,
      Structures: false,
      Supports: false,
      Trucks: false,
      Cases: false,
      Racks: false,
      Gels: false,
      ElectricalOverview: true,
      Connections: true,
      Notes: true,
      Structural: false,
      Overview: true
  },
  ObjectInfo:
  {
    Note: true,
    RenderingOptions: true,
    GlobalPosition: true,
    TimeAndUser: true,
    Inventory: true,
    History: false,
    Magnet: true,
    Line: true,
    CustomTransform: true,
    LinkedGraphic: true,
    ElectricalProperties: true,
    FixtureData: true,
    FixtureDesign: true,
    FixtureType: true,
    ExtraElectricalInfo: true,
    WireProperties: true,
    Support:false,
    PrintLabelFieldProperties: true,
    PrintLabel: true,
    Structure: false,
    Load: false,
    SupportProperties: true,
    GeometryOrigin: true,
    Dimension: true,
    ObjectPath: true,
    InfluenceLine: true,
    Structural: false,
    Cables: true,
    ContainerSupport: false,
    InventoryGeometries: false,
  }
}

export let AllWorkspace = {
  Tools:
  {
      NewObject: true,
      Select: true,
      Move: true,
      Scale: true,
      Rotate: true,
      Mirror: true,
      Align: true,
      Orbit: true,
      Measure: true,
      Patch: true,
  },
  Navigation:
  {
      Classes: true,
      Layers: true,
      SelectionGroups: true,
      Presets: true,
      TimePhases: true,
      Users: true,
      Worksheets: true,
      Tasks: true,
      SavedViews: true,
      ColorCodes: true,
      Departments: true,
      Cases: true,
      Racks: true,
      Containers: true,
      Errors: true,
      LoadGroups: true,
      LoadCombinations: true,
      PrintLabels: true,
      InfluenceLines: true,
  },
  Worksheet:
  {
      Properties: true,
      Inventory: true,
      Structures: true,
      Supports: true,
      Trucks: true,
      Cases: true,
      Racks: true,
      Gels: true,
      ElectricalOverview: true,
      Connections: true,
      Notes: true,
      Structural: true,
      Overview: true
  },
  ObjectInfo:
  {
    Note: true,
    RenderingOptions: true,
    GlobalPosition: true,
    TimeAndUser: true,
    Inventory: true,
    History: true,
    Magnet: true,
    Line: true,
    CustomTransform: true,
    LinkedGraphic: true,
    ElectricalProperties: true,
    FixtureData: true,
    FixtureDesign: true,
    FixtureType: true,
    ExtraElectricalInfo: true,
    WireProperties: true,
    Support:true,
    PrintLabelFieldProperties: true,
    PrintLabel: true,
    Structure: true,
    Load: true,
    SupportProperties: true,
    GeometryOrigin: true,
    Dimension: true,
    ObjectPath: true,
    InfluenceLine: true,
    Structural: true,
    Cables: true,
    ContainerSupport: true,
    InventoryGeometries: true,
  }
}

export function ToolModeNameToEnum(name: string){
  return {
    "NewObject": TOOL_MODE.Insert,
    "Select"   : TOOL_MODE.Select,
    "Move"     : TOOL_MODE.Move,
    "Scale"    : TOOL_MODE.Scale,
    "Rotate"   : TOOL_MODE.Rotate,
    "Mirror"   : TOOL_MODE.Mirror,
    "Align"    : TOOL_MODE.Align,
    "Orbit"    : TOOL_MODE.Orbit,
    "Measure"  : TOOL_MODE.Measure,
    "Patch"    : TOOL_MODE.Patch,
  }[name]
}

export function CheckWorkspace() 
{
  if(!localStorage.getItem("workspace"))
  {
      localStorage.setItem("workspace", JSON.stringify(AllWorkspace))
  }

  return JSON.parse(localStorage.getItem("workspace"))
}

export function getProjectName(project)
{
  let projectName = project?.DrawingSettings?.ProjectPrettyName ? project?.DrawingSettings?.ProjectPrettyName : project?.name

  projectName = projectName?.split(" ").join("_")

   return projectName
}

export function getHexcolor(color)
{
  let hexColor = cie2hex({fx: color?.X, fy: color?.Y, f_Y: color?.Z})
  return hexColor
}

export function getHexColorToGradient(hexColors: string[])
{
  let div = 100 / hexColors.length
  let out = [`${hexColors[0]} ${Math.floor(div)}%`]
  for(let i = 1; i < hexColors.length - 1; ++i){
    out.push(`${hexColors[i]} ${Math.floor(div * i)}%`)
    out.push(`${hexColors[i]} ${Math.floor(div * (i + 1))}%`)
  }

  out.push(`${hexColors[hexColors.length - 1]} ${Math.floor(div * (hexColors.length - 1))}%`)


  return `linear-gradient(45deg, ${out.join(",")})`
}

// deep merge
export function MergeObjects(objectlist: any[])
{

  // create a new object
  let target = {};

  // deep merge the object into the target object
  const merger = (obj: any) => {
      for (let prop in obj) {
          if (obj[prop] !== undefined) {
              if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                  // if the property is a nested object
                  target[prop] = MergeObjects([target[prop], obj[prop]]);
              } else {
                  // for regular property
                  target[prop] = obj[prop];
              }
          }
      }
  };

  // iterate through all objects and 
  // deep merge them with target
  for (let i = 0; i < objectlist.length; i++) {
      merger(objectlist[i]);
  }

  return target;
};

// deep compare
export function CompareObjects(...objToCompare: any[]) {
  var i, l, leftChain, rightChain;

  function compare2Objects(x, y) {
    var p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true;
    }

    // Compare primitives and functions.     
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if ((typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      }
      else if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      }
      else if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof (x[p])) {
        case 'object':
        case 'function':

          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {

    leftChain = []; //Todo: this can be cached
    rightChain = [];

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
}

export function extractNumber(inputString)
{
    let result = 0
    const numberRegex = /\b\d+(?:\.\d+)?\b/;
    if(inputString){
        const match = inputString.match(numberRegex);
        if (match) {
            result =  Number(match[0]);
        }
    }
    return result
}